import { call, put, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import md5 from 'md5';
import fp from 'lodash/fp';

import { setIsLoading } from '../actions/common';
import { API } from '../../api';
import {
  setCerts,
  setCopyForEdit,
  setDocs,
  setModerationItemComment,
  setPhoto,
  setProduct,
  setProductFirstStepTemplate,
  setSignData,
} from '../actions/products';
import { setEnum } from '../actions/enums';
import { routes } from '../../routes';
import { prepareComments, prepareDocs, preparePhoto } from '../../utils/utils';
import { EimzoService, prepareAllCertificateList } from '../../libs/Eimzo';
import {
  showErrorToast,
  showSuccessToast,
} from '../../components/toastWrapper';
import { Translate } from '../../components/translate';
import {
  getProductNewFiles,
  getProductParameters,
} from '../../pages/products/form/utils';
import { setComments } from '../actions/tasks';

import { loadCategoriesListSaga, loadTnvedByCategorySaga } from './enums';

export function* loadGtinListSaga({ payload }) {
  try {
    const json = yield API.rest.product.list(payload);
    yield put(
      setEnum({
        name: 'gtins',
        values: [...json],
      })
    );
  } catch (err) {
    yield showErrorToast({ errors: err });
  }
}

export function* loadTemplateSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const template = yield API.rest.product.getTemplate(payload.data);
    yield put(setProduct(template));
    yield put(setIsLoading(false));
    yield payload.onFinish(false);
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
    yield payload.onFinish(true);
  }
}

function renameFile(originalFile: File, newName: string) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

export function* uploadFilesSaga(files) {
  try {
    const data = files.reduce(
      (acc, item) => {
        const fileName = item?.externalId
          ? `${item?.externalId}_${item.file.name}`
          : item.file.name;
        acc.file.push(renameFile(item.file, fileName));
        acc.metaData.files[fileName] = {
          externalId: item?.externalId,
        };
        return acc;
      },
      { file: [], metaData: { files: {} } }
    );

    const formData = new window.FormData();
    for (const file of data.file) {
      formData.append('file', file);
    }

    formData.append(
      'meta-data',
      new Blob([JSON.stringify(data.metaData)], {
        type: 'application/json',
      })
    );
    return yield API.rest.product.uploadFiles(formData, '');
  } catch (err) {
    yield showErrorToast({ errors: err });
    return {};
  }
}

export function* updateProductDraftSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const {
      data,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      attributes_units,
      history,
      isEditingNC,
      isEditingByOperator,
      filter,
      removedPhotos,
    } = payload;

    const newFiles = getProductNewFiles(data);

    let updatedFiles = [];
    if (newFiles.length) {
      updatedFiles = yield call(uploadFilesSaga, newFiles);
    }

    const { newData, id } = getProductParameters(data, updatedFiles);

    Object.keys(removedPhotos).forEach((side) => {
      if (!newData?.[side]) {
        newData[side] = null;
      }
    });
    if (!newData?.technical_photo?.files) {
      newData['technical_photo'] = null;
    }

    const newDataFiltered = fp.pipe(fp.omitBy(filter))(newData);

    isEditingNC || isEditingByOperator
      ? yield API.rest.product.updateNCDraft(id, {
          attributes: newDataFiltered,
          attributes_units,
        })
      : yield API.rest.product.updateDraft(id, {
          tnved: data.tnved,
          attributes: newDataFiltered,
          attributes_units,
        });

    yield put(setIsLoading(false));
    history.push(routes.products());
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* createProductDraftSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const { data, history } = payload;
    const newFiles = getProductNewFiles(data.attributes);
    let updatedFiles = [];
    if (newFiles.length) {
      updatedFiles = yield call(uploadFilesSaga, newFiles);
    }

    const { newData } = getProductParameters(data.attributes, updatedFiles);
    yield API.rest.product.createDraft({
      ...data,
      attributes: newData,
    });
    yield put(setIsLoading(false));
    history.push(routes.products());
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* getProductFirstStepTemplateSaga() {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.attributes.firstStepTemplate();
    yield put(setProductFirstStepTemplate(json));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* getProductSampleSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.product.item(payload);
    if (json?.product_group?.code) {
      yield call(loadCategoriesListSaga, {
        payload: json?.product_group?.code,
      });
    }
    if (json?.category) {
      yield call(loadTnvedByCategorySaga, {
        payload: json?.category,
      });
    }
    yield put(setProduct(json));
    const photo = preparePhoto([
      ...json?.mandatory_attributes,
      ...json?.optional_attributes,
    ]);

    const docs = prepareDocs(json);
    yield put(setDocs(docs));

    yield put(setPhoto(photo));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* getProductCopyForEditSaga({ payload }) {
  try {
    const json = yield API.rest.product.getCopyForEdit(payload);
    yield put(push(routes.products(`edit-published/${json.id}`)));
    yield put(
      setCopyForEdit({
        ...json,
      })
    );
  } catch (err) {
    yield showErrorToast({ errors: err });
  }
}

export function* loadSignData({ payload }) {
  const id = payload;
  try {
    const json = yield API.rest.product.getSingData(id);
    yield put(setSignData(json));
  } catch (e) {
    yield showErrorToast({ errors: e });
  }
}

export function* loadCerts({ payload }) {
  try {
    const id = payload;
    yield EimzoService.init();
    const certkey = yield EimzoService.enumerateCertificates(
      'list_all_certificates',
      'certkey',
      null
    );
    const pfx = yield EimzoService.enumerateCertificates(
      'list_all_certificates',
      'pfx',
      null
    );
    const ftjc = yield EimzoService.enumerateCertificates(
      'list_all_keys',
      'ftjc',
      ['']
    );
    const certList = prepareAllCertificateList(certkey, pfx, ftjc);
    if (certList.length) {
      const certs = certList
        .filter((el) => el.validTo.getTime() > new Date().getTime())
        .map((el) => ({
          label: `${el.name} - ${el.position}`,
          value: el.id,
          cert: el,
        }));
      yield put(setCerts(certs));
      yield call(loadSignData, {
        payload: id,
      });
    }
  } catch (e) {
    if (e) {
      yield showErrorToast({ errors: e });
    }
  }
}

export function* moderateProductSaga({ payload }) {
  const { id, certificate, withoutCert, noVerification } = payload;
  try {
    const signData = yield select((state) => state.products.signData);

    const body = {};
    if (signData) {
      if (withoutCert) {
        body.signature = md5(signData).toUpperCase();
      } else if (!noVerification) {
        const key = yield EimzoService.loadKey(certificate);
        const sign = yield EimzoService.sign(key.keyId, signData, true);
        body.signature = sign.pkcs7_64;
      }
    }
    yield put(setIsLoading(true));
    yield API.rest.product.toModerate(id, body);
    yield showSuccessToast({
      content: Translate('Товар отправлен на модерацию!'),
    });
    const json = yield API.rest.product.item(id);
    yield put(setProduct(json));
    yield put(setIsLoading(false));
  } catch (e) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: e });
  }
}

export function* getCommentsAfterModerationSaga({ payload }) {
  try {
    const json = yield API.rest.product.getComments(payload);
    const commentObj = prepareComments(json?.comments || []);
    yield put(setModerationItemComment(commentObj));
    yield put(setComments(commentObj));
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}
